<template>
  <div>
    <b-modal
      :id="modalId"
      v-model="showModal"
      centered
      modal-class="custom-modal-variant"
      header-close-variant="cader-gray"
      hide-footer
    >
      <template #modal-title>
        <div
          class="
            d-flex
            align-items-center
            justify-content-center
            text-dark-blue
          "
        >
          <span>Share Selection's Link</span>
        </div>
      </template>

      <!-- Display generated link -->
      <div
        v-if="shareLink && !loading && !error"
        class="link-container d-flex flex-column align-items-center"
      >
        <p class="text-center">
          Your shareable link is ready!
        </p>
        <p class="text-center mb-2">
          You can copy it below:
        </p>
        <div class="d-flex align-items-center w-100">
          <input
            type="text"
            :value="shareLink"
            class="form-control mr-2"
            readonly
          >

          <b-button
            :class="{ active: isCopied }"
            :disabled="isCopied"
            variant="outline-secondary"
            class="d-flex align-items-center"
            @click="copyLink"
          >
            <span class="mr-1"> Copy </span>
            <feather-icon
              icon="CopyIcon"
              size="20"
            />
          </b-button>
        </div>
        <p class="text-muted mt-3">
          This link can be shared publicly and allows others to view the
          selected products.
        </p>
      </div>

      <!-- Loading spinner -->
      <div
        v-else-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="min-height: 219px"
      >
        <b-spinner small />
        <span class="ml-2">Generating link...</span>
      </div>

      <!-- Error message -->
      <div
        v-else-if="error"
        class="error-container d-flex justify-content-center align-items-center"
        style="min-height: 219px"
      >
        <span class="mr-4px mb-4px">
          <feather-icon
            icon="AlertTriangleIcon"
            size="20"
            class="text-danger"
          />
        </span>
        <span>Failed to generate link</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'
import { apiToastSuccessV2 } from '@/@core/utils/toast'
import constants from '@/constants'
import { SHARE_LINK_WISHLIST } from '@/store/modules/shop.module'
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  name: 'ShareLinkSelectionModal',
  components: { BButton, BSpinner, FeatherIcon },
  props: {
    modalId: {
      type: String,
      default: 'modal-share-link-selection',
    },
    wishlistId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      loading: false,
      shareLink: '',
      error: null,
      isCopied: false,
    }
  },
  watch: {
    showModal(newVal) {
      if (newVal) this.getLink()
    },
  },
  methods: {
    async getLink() {
      this.loading = true
      try {
        const response = await this.requestShareLink()
        this.processResponse(response)
        analytics.track(constants.TRACKS.ACTIONS.GET_SHARE_LINK_SUCCESS)
      } catch (error) {
        this.handleError(error)
        analytics.track(constants.TRACKS.ACTIONS.GET_SHARE_LINK_FAILURE)
      } finally {
        this.loading = false
      }
    },

    async requestShareLink() {
      return await this.$store.dispatch(SHARE_LINK_WISHLIST, {
        payload: { isActive: true },
        wishlistId: this.wishlistId,
      })
    },

    processResponse(response) {
      const { data } = response
      if (data?.response?.publicToken) {
        const domain = this.getDomain()
        this.shareLink = `${domain}/wishlist/public/${data.response.publicToken}`
        this.isCopied = false
        this.error = null
      } else {
        throw new Error('Invalid response from API')
      }
    },

    getDomain() {
      return `${window.location.protocol}//${window.location.host}`
    },

    handleError(error) {
      console.error(error)
      this.error = error
    },

    copyLink() {
      navigator.clipboard.writeText(this.shareLink)
      analytics.track(constants.TRACKS.ACTIONS.COPY_LINK_BUTTON_CLICKED)
      apiToastSuccessV2('Link copied to clipboard')

      this.isCopied = true
      setTimeout(() => {
        this.isCopied = false
      }, 4000)
    },
  },
}
</script>

<style scoped>
.text-dark-blue {
  color: #2c3e50;
}

.link-container,
.loading-container,
.error-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

input.form-control {
  cursor: copy;
  background-color: #f9f9f9;
}

.text-muted {
  color: #6c757d;
}

.mr-4px {
  margin-right: 4px;
}

.active {
  background-color: #6c757d !important;
  color: #fff !important;
}

.mb-4px {
  margin-bottom: 4px;
}
</style>
