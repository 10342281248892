<template>
  <b-modal
    :id="modalId"
    size="md"
    button-size="lg"
    title="Selections sharing results"
    hide-footer
    centered
  >
    <div>
      <h5>{{ getSubHeader }}</h5>
      <div class="errors-block mt-1">
        <div v-if="successfullySharedSelections.length">
          <div class="font-weight-bold text-success">
            Successfully shared:
          </div>
          <ul>
            <li
              v-for="(message, index) of successfullySharedSelections"
              :key="'success_message_' + index"
              class="py-25 ml-50"
            >
              <span class="text-secondary">{{ message.name }}</span>
            </li>
          </ul>
        </div>

        <div v-if="failedSharedSelections.length">
          <div class="font-weight-bold text-warning">
            Follow up:
          </div>
          <ul>
            <li
              v-for="(message, index) of failedSharedSelections"
              :key="'error_message_' + index"
              class="py-25 ml-50"
            >
              <span class="text-secondary">{{ message.name }}</span>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <div class="my-1 d-flex justify-content-center">
      <b-button
        v-if="failedSharedSelections.length"
        variant="primary"
        class="mr-1"
        :disabled="isProcessingFollowUp"
        @click="onClickFollowUp()"
      >
        {{ isProcessingFollowUp ? 'Sending...' : 'Follow up' }}
      </b-button>
      <b-button
        variant="outline-primary"
        :disabled="isProcessingFollowUp"
        @click="onCloseModal()"
      >
        {{ failedSharedSelections.length ? "Don't follow up" : "Close" }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import { kpEndpoint, kpRequest } from '@/axios'
import { BButton, BModal } from 'bootstrap-vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

const { SHARE_MULTIPLE_SELECTIONS } = constants.TRACKS.ACTIONS

export default {
  name: 'ShareSelectionsErrorModal',
  components: {
    BModal,
    BButton,
  },
  props: {
    modalId: {
      type: String,
      default: 'modal-share-multiple-selection-fails',
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isProcessingFollowUp: false,
    }
  },
  computed: {
    successfullySharedSelections() {
      return this.messages.filter(message => !message.isError) || []
    },
    failedSharedSelections() {
      return this.messages.filter(message => message.isError) || []
    },
    isAllSuccess() {
      return this.successfullySharedSelections?.length === this.messages?.length
    },
    isAllFailed() {
      return this.failedSharedSelections?.length === this.messages?.length
    },
    seletedRetailer() {
      return this.$store.getters?.getSelectedRetailer || null
    },
    getSubHeader() {
      const retailerName = this.seletedRetailer?.entityName || 'retailer'
      if (this.isAllSuccess) {
        const count = this.successfullySharedSelections.length
        return count === 1 ? `You successfully shared the selection with ${retailerName}:` : `You successfully shared ${count} selections with ${retailerName}:`
      }
      if (this.isAllFailed) {
        return this.failedSharedSelections.length === 1 ? `You already shared the chosen selection with ${retailerName}. Do you want to follow up on this selection?` : `You already shared chosen selections with ${retailerName}. Do you want to follow-up on these selections?`
      }
      return `You already shared some of the chosen selections with ${retailerName}. Do you want to follow-up on already shared selections?`
    },
  },
  methods: {
    getClass(isError) {
      if (isError) {
        return 'bg-danger'
      }
      return 'bg-success'
    },
    onCloseModal() {
      this.$bvModal.hide(this.modalId)
      analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_CLICKS_DONT_FOLLOW_UP_BUTTON)
      analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_CLOSES_FOLLOW_UP_POPUP)
    },
    onClickFollowUp() {
      const trackObject = {
            retailer: this.seletedRetailer,
            selections: this.failedSharedSelections,
      }
      analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_CLICKS_FOLLOW_UP_BUTTON, trackObject)

      this.isProcessingFollowUp = true
      const payload = {
        retailerId: this.seletedRetailer?.retailerId,
        selectionIds: this.failedSharedSelections.map(selection => selection._id),
      }
      kpRequest({
        ...kpEndpoint.wishlist.followUp,
        payload,
      })
        .then(res => {
          this.onCloseModal()
          apiToastSuccess(res?.data?.message || 'Email follow-up sent. You could see open and click data in the Sales Insights section.')
          analytics.track(SHARE_MULTIPLE_SELECTIONS.FOLLOW_UP_SUCCESSED, trackObject)
        })
        .catch(err => {
          apiToastError(err)
          console.error(err)
          analytics.track(SHARE_MULTIPLE_SELECTIONS.FOLLOW_UP_FAILED, trackObject)
        })
        .finally(() => {
          this.isProcessingFollowUp = false
        })
    },
  },
}
</script>

<style lang="scss">
.errors-block {
  max-height: 400px;
  overflow-y: auto;
}
</style>
