<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.03443 4.16939V0.614746L12.9003 4.48067L16 7.5803L12.8453 10.2093L9.03443 13.3853V9.90708C2.34401 9.4028 -1.57661e-05 13.2745 -1.57661e-05 13.2745C-1.57661e-05 10.8623 0.198741 8.35891 2.09515 6.46332C4.29956 4.25809 7.492 4.10204 9.03443 4.16939Z"
      fill="#16ACD9"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShareIcon',
}
</script>
