<template>
  <div>
    <b-modal
      :id="modalId"
      hide-header
      hide-header-close
      hide-footer
      size="lg"
      centered
    >
      <div class="p-1">
        <div class="pb-1 d-flex justify-content-between align-items-center">
          <div class="h2 font-weight-bold">
            Download Wishlist
          </div>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="closePopup"
          />
        </div>
        <div class="text-primary font-weight-bold h4">
          Select a file format
        </div>
        <div class="row mx-0">
          <div
            v-for="fileFormat of options"
            :key="fileFormat.value"
            class="col-3 p-25"
            @click="onSelectType(fileFormat.value)"
          >
            <div
              class="download-option d-flex flex-column justify-content-center align-items-center rounded"
              :class="{ 'active': downloadType.value === fileFormat.value }"
            >
              <component :is="getComponent(fileFormat.fileType)" />
              <small class="mt-1">{{ fileFormat.text }}</small>
            </div>
          </div>
        </div>

        <div class="w-100 mt-2 d-flex justify-content-center">
          <div>
            <b-button
              variant="outline-primary"
              @click="closePopup"
            >
              Cancel
            </b-button>
          </div>
          <div class="ml-1">
            <b-button
              variant="primary"
              :disabled="!downloadType || isLoading"
              @click="onClickDownload"
            >
              {{ isLoading ? 'Downloading...' : 'Download' }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import XlsxIcon from '@/@core/assets/svg-components/XlsxIcon.vue'
import { FILE_FORMATS } from '@/constants'
import PdfIcon from '@/@core/assets/svg-components/PdfIcon.vue'
import ZipIcon from '@/@core/assets/svg-components/ZipIcon.vue'
import PptxIcon from '@/@core/assets/svg-components/PptxIcon.vue'
import { BButton, BModal } from 'bootstrap-vue'
import { apiToastWarning } from '@/@core/utils/toast'

export default {
  name: 'DownloadWishlistModalV2',
  components: {
    BModal,
    BButton,
  },
  props: {
    modalId: {
      type: String,
      default() {
        return 'download_wishlist_modal'
      },
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    isDownloading: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      downloadType: this.options[0] || null,
      isLoading: this.isDownloading,
    }
  },
  watch: {
    isDownloading(val) {
      this.isLoading = val
    },
  },
  emits: ['on-click-download'],
  methods: {
    // On select option
    onSelectType(fileType) {
      const selectedOption = this.options.find(option => fileType === option.value)
      if (!selectedOption) {
        apiToastWarning(`File type "${fileType}" not available`)
        return
      }
      this.downloadType = selectedOption
    },
    // On click cancel
    closeDownloadPopup() {
      this.isLoading = false
      this.downloadType = null
      this.$bvModal.hide(this.modalId)
    },
    // On click download
    onClickDownload() {
      this.$emit('on-click-download', this.downloadType)
    },
    getComponent(format) {
      const formatLower = format?.toLowerCase()
      if (formatLower.includes(FILE_FORMATS.PDF)) {
        return PdfIcon
      }
      switch (formatLower) {
        case FILE_FORMATS.PDF.toLowerCase():
          return PdfIcon
        case FILE_FORMATS.XLSX.toLowerCase():
          return XlsxIcon
        case FILE_FORMATS.ZIP.toLowerCase():
          return ZipIcon
        case FILE_FORMATS.PPTX.toLowerCase():
          return PptxIcon
        default:
          return XlsxIcon
      }
    },
    closePopup() {
      this.$bvModal.hide(this.modalId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/@core/scss/base/bootstrap-extended/_variables.scss";

.download-option {
  cursor: pointer;
  height: 100px;
  background-color: $gray-200;

  &:hover,
  &.active {
    border: 1px solid $black;
  }
}
</style>
