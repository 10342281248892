<template>
  <b-card
    no-body
    class="wishlist-card"
  >
    <div>
      <b-link
        :to="{
          name: 'selection-products',
          params: { wishlistId: wishlist._id },
        }"
      >
        <div class="image">
          <b-img-lazy
            :src="getLogo"
            :alt="wishlist.retailerName"
            class="wishlist-image"
          />
        </div>
        <div
          class="send-notification-section"
        >
          <div class="wishlist-body pt-75 pb-50">
            <div class="title">
              {{ wishlist.retailerName }}
            </div>
            <div class="text-truncate mt-50">
              {{ wishlist.collectionName }}
            </div>
            <div class="count">
              {{ wishlist.count || 0 }} product{{ wishlist.count > 1 ? 's' : '' }}
            </div>
            <div
              v-if="isBrand && (isEntityAdmin || isSalesManager) && wishlist.salesRep"
              class="mt-25 color-sonic-silver"
            >
              Sales representative: {{ wishlist.salesRep }}
            </div>
          </div>
          <b-button
            class="notication-btn-position"
            variant="custom-primary"
            @click.prevent.stop="openWishlistNotification(wishlist.retailerName, wishlist.retailerEmail)"
          >
            Send a Message
          </b-button>
        </div>
      </b-link>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BLink,
  BImgLazy,
  BButton,
} from 'bootstrap-vue'
import { defaultImageUrl } from '@/@core/utils/utils'
import UserRoleMixinVue from '../UserRoleMixin.vue'

export default {
  components: {
    BCard,
    BLink,
    BImgLazy,
    BButton,
  },
  mixins: [UserRoleMixinVue],
  props: {
    wishlist: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  emits: ['open-wishlist-notification'],
  computed: {
    getLogo() {
      if (this.wishlist.retailerLogo) {
        return this.wishlist.retailerLogo
      }
      if (this.wishlist.collectionLogo) {
        return this.wishlist.collectionLogo
      }
      return defaultImageUrl
    },
  },
  methods: {
    openWishlistNotification(name, email) {
      this.$emit('open-wishlist-notification', name, email)
    },
  },
}
</script>
