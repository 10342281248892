<template>
  <b-card
    no-body
    class="wishlist-card"
    @click="goToWishlist()"
  >
    <div class="wishlist-card-details">
      <b-link>
        <div class="image">
          <b-img-lazy
            :src="
              wishlist.collectionLogo
                ? wishlist.collectionLogo
                : defaultImageUrl
            "
            :alt="wishlist.name"
            class="wishlist-image"
          />
          <b-dropdown
            v-if="!isDashboardPage"
            no-caret
            class="wishlist-actions-dropdown"
            right
            variant="outline-none"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
              />
            </template>
            <div class="infoText">
              {{ getWishlistTitle(isRetailer) }} Actions
            </div>
            <b-dropdown-item @click.stop="onClickRenameWishlist(wishlist._id)">
              Rename
            </b-dropdown-item>
            <b-dropdown-item
              @click.stop="onClickDownloadWishlist(wishlist._id)"
            >
              Download
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isBrand"
              @click.stop="onClickShareWishlist(wishlist._id)"
            >
              Share
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isBrand"
              @click.stop="onClickShareLinkWishlist(wishlist._id)"
            >
              Share public link
            </b-dropdown-item>

            <b-dropdown-item @click.stop="onClickDeleteWishlist(wishlist._id)">
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="wishlist-body py-75">
          <div class="d-flex justify-content-between align-items-center">
            <div
              :id="`tooltip_wishlist_name_${wishlist._id}`"
              class="title title-height"
              @mouseover="
                showTooltip(
                  `tooltip_wishlist_name_${wishlist._id}`,
                  wishlist._id
                )
              "
            >
              {{ wishlist.name }}
            </div>
            <span
              v-if="isBrand && !wishlist.sharedDate"
              v-b-modal.stop.prevent="`modal-share-wishlist-${wishlist._id}`"
              class="share-icon-position mr-50"
              @click.stop.prevent
            >
              <message-icon />
            </span>
          </div>
          <b-tooltip
            v-if="wishlist._id === tooltipId"
            :target="`tooltip_wishlist_name_${wishlist._id}`"
            placement="bottom"
            triggers="hover"
          >
            {{ wishlist.name }}
          </b-tooltip>
          <div class="count mt-50">
            {{ wishlist.count || 0 }} product{{ wishlist.count > 1 ? 's' : '' }}
          </div>
          <div
            v-if="wishlist.collectionType"
            class="mt-25 mr-25 collection-type"
            :class="parseCollectionTypeClass(wishlist.collectionType)"
          >
            {{ getCollectionTypeText(wishlist.collectionType) }}
          </div>
          <div
            v-if="isDashboardPage && isRetailer"
            class="mt-25 collection-type"
            :class="wishlist.isBrandInitiated ? 'shared-bg' : 'created-bg'"
          >
            {{ wishlist.isBrandInitiated ? 'Shared with me' : 'Created by me' }}
          </div>
          <div class="mt-25 mb-50">
            {{ parseCollectionDate(wishlist.collectionExpiry) }}
          </div>
          <div
            v-if="
              isBrand && (isEntityAdmin || isSalesManager) && wishlist.salesRep
            "
            class="mt-25 color-sonic-silver"
          >
            {{
              wishlist.isCreatedBySelf
                ? selfOption.name
                : 'Sales representative: ' + wishlist.salesRep
            }}
          </div>
          <div
            v-if="isBrand"
            class="color-sonic-silver"
          >
            <span v-if="wishlist.sharedDate">
              Shared with: {{ wishlist.retailerName || wishlist.email }}
            </span>
            <div v-else>
              Not shared yet
            </div>
          </div>
          <div
            v-if="!isDashboardPage"
            class="color-sonic-silver"
          >
            <span v-if="wishlist.sharedDate && isBrand">
              Shared: {{ formattedDate(wishlist.sharedDate) }}</span>
            <span
              v-else
            >Created: {{ formattedDate(wishlist.createdAt) }}
            </span>
          </div>
          <div class="color-sonic-silver">
            Updated: {{ formattedDate(wishlist.updatedAt) }}
          </div>
        </div>
      </b-link>
    </div>
    <wishlist-action
      v-if="!isDashboardPage"
      :wishlist="wishlist"
      @fetchWishlists="$emit('fetchWishlists')"
      @open-referral-model="openReferralModel"
    />
  </b-card>
</template>

<script>
import {
  getWishlistTitle,
  isEllipsisActive,
  parseCollectionDate,
  getCollectionTypeText,
  parseCollectionTypeClass,
  defaultImageUrl,
} from '@/@core/utils/utils'
import {
  BCard,
  BLink,
  BImgLazy,
  BTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import MessageIcon from '@/@core/assets/svg-components/MessageIcon.vue'
import { getSelfOption } from '@/service/user.service'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { mapState } from 'vuex'
import { apiToastErrorV2 } from '@/@core/utils/toast'
import WishlistAction from './WishlistAction.vue'
import UserRoleMixin from '../UserRoleMixin.vue'

const { formattedDate } = utils

export default {
  components: {
    BCard,
    BLink,
    BImgLazy,
    BTooltip,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    WishlistAction,
    MessageIcon,
  },
  mixins: [UserRoleMixin],
  props: {
    wishlist: {
      type: Object,
      default() {
        return {}
      },
    },
    isDashboardPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipId: '',
      defaultImageUrl,
      formattedDate,
      getWishlistTitle,
      parseCollectionDate,
      getCollectionTypeText,
      parseCollectionTypeClass,
      selfOption: getSelfOption(),
    }
  },
  emits: ['fetchWishlists'],
  computed: {
    ...mapState({
      profile: state => state.auth.profileData,
    }),
  },
  methods: {
    onClickRenameWishlist(wishlistId) {
      this.$bvModal.show(`modal-rename-wishlist-${wishlistId}`)
    },
    onClickDownloadWishlist(wishlistId) {
      this.$bvModal.show(`modal-download-wishlist-${wishlistId}`)
    },
    onClickShareWishlist(wishlistId) {
      this.$bvModal.show(`modal-share-wishlist-${wishlistId}`)
      analytics.track(
        constants.TRACKS.ACTIONS.BRAND_OPENS_SHARE_SELECTION_POPUP,
        { wishlist: this.wishlist },
      )
    },
    async onClickShareLinkWishlist(wishlistId) {
      analytics.track(constants.TRACKS.ACTIONS.BRAND_OPENS_SHARE_LINK_POPUP, {
        wishlist: this.wishlist,
      })

      if (!this.wishlist.count || this.wishlist.count === 0) {
        apiToastErrorV2('Cannot share an empty selection')
      } else {
        this.$bvModal.show(`modal-share-link-selection-${wishlistId}`)
      }
    },
    onClickDeleteWishlist(wishlistId) {
      this.$bvModal.show(`modal-delete-wishlist-${wishlistId}`)
    },
    showTooltip(id, productId) {
      const wishlistNameElement = document.getElementById(id)
      if (wishlistNameElement && isEllipsisActive(wishlistNameElement)) {
        this.tooltipId = productId
      }
    },
    openReferralModel(email, wishlist) {
      this.$emit('open-referral-model', email, wishlist)
    },
    goToWishlist() {
      this.$router.push({
        name: this.isRetailer ? 'wishlist-products' : 'selection-products',
        params: { wishlistId: this.wishlist._id },
      })
      if (this.isDashboardPage) {
        analytics.track(
          constants.TRACKS.ACTIONS.USER_CLICKS_A_WISHLIST_ON_DASHBOARD,
          { wishlist: this.wishlist, profile: this.profile },
        )
      }
    },
  },
}
</script>
