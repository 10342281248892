<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.0861 2.91356L13.0868 6.91425L4.39946 15.6016L0.832525 15.9953C0.355017 16.0482 -0.0484267 15.6444 0.00469921 15.1669L0.401581 11.5975L9.0861 2.91356ZM15.5612 2.31793L13.6827 0.43946C13.0968 -0.146487 12.1465 -0.146487 11.5605 0.43946L9.7933 2.20668L13.794 6.20737L15.5612 4.44015C16.1472 3.85389 16.1472 2.90388 15.5612 2.31793Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'PencilIcon',
}
</script>
