<template>
  <b-modal
    id="send-wishlist-mail-notification"
    modal-class="custom-modal-variant email-notification-model"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    :size="showForm ? 'lg' : 'md'"
    hide-footer
    header-class="py-1"
    @show="showModal"
  >
    <template #modal-title>
      <div class="elements-center color-dark-blue">
        <speaker-icon />
        <span class="ml-1"> Send a message </span>
        <feather-icon
          icon="XIcon"
          size="20"
          class="close-icon"
          @click="closeNotificationModal"
        />
      </div>
    </template>
    <div class="notification-block scroll-bar">
      <template v-if="showForm">
        <div v-show="!showExpandedView">
          <div class="header">
            This message will be shared through the platform. Any responses will be processed by our team accordingly
          </div>
          <div class="my-1">
            <b-badge class="notification-badge">
              {{ retailerName }}
              <feather-icon
                icon="XIcon"
                size="16"
                class="ml-50"
              />
            </b-badge>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Subject"
            vid="name"
            rules="min:5"
          >
            <b-form-group label-class="color-neutral-black">
              <template #label>
                Subject <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model="subject"
                placeholder="Type in your message subject"
              />
              <span
                v-if="errors.length"
                class="text-danger font-small-4"
              >{{ errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </div>
        <div
          v-show="showExpandedView"
          class="mb-1"
        >
          <b-button
            variant="outline-secondary-info"
            @click="showExpandedView = false"
          >
            <feather-icon icon="ChevronLeftIcon" />
            Back
          </b-button>
        </div>
        <b-form-group
          label-class="color-neutral-black"
          class="position-relative"
        >
          <template #label>
            Message <span class="text-danger">*</span>
          </template>
          <vue-editor
            v-model="message"
            :editor-toolbar="VUE_EDITOR_DEFAULT_EDIT_OPTIONS"
            placeholder="Type in your message"
            :class="showExpandedView ? 'expanded' : ''"
          />
          <span
            v-if="message && hasValidMessage"
            class="text-danger font-small-4"
          >Message should be atleast 20 characters</span>
          <span
            v-show="!showExpandedView"
            class="expand-icon"
            @click="showExpandedView = true"
          >
            <expand-icon />
          </span>
        </b-form-group>
      </template>
    </div>
    <div
      v-show="!showExpandedView"
      class="elements-center"
    >
      <b-button
        variant="outline-secondary-info"
        class="mr-1"
        @click="closeNotificationModal"
      >
        Cancel
      </b-button>
      <b-button
        :disabled="!validateInput || isLoading"
        variant="secondary-info"
        @click="sendBroadcastMessage"
      >
        {{ isLoading ? 'Sending...' : 'Send' }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import SpeakerIcon from '@/@core/assets/svg-components/SpeakerIcon.vue'
import store from '@/store'
import { SEND_BROADCAST_MAIL } from '@/store/modules/notification.module'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { VueEditor } from 'vue2-editor'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ExpandIcon from '@/@core/assets/svg-components/ExpandIcon.vue'
import { formatObject, removeHtmlTags } from '@/@core/utils/utils'
import analytics from '@/@core/utils/analytics'
import constants, { VUE_EDITOR_DEFAULT_EDIT_OPTIONS } from '@/constants'

export default {
  components: {
    BModal,
    SpeakerIcon,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BButton,
    VueEditor,
    BBadge,
    FeatherIcon,
    ExpandIcon,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    retailerEmail: {
      type: String,
      default: '',
    },
    retailerName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      subject: '',
      message: '',
      showExpandedView: false,
      recipientList: [],
      isLoaded: false,
      showForm: true,
      isLoading: false,
      VUE_EDITOR_DEFAULT_EDIT_OPTIONS,
    }
  },
  computed: {
    validateInput() {
      const isValid = !(this.subject.length < 5) && !this.hasValidMessage
      return isValid
    },
    hasValidMessage() {
      return removeHtmlTags(this.message).length < 20
    },
  },
  methods: {
    sendBroadcastMessage() {
      this.isLoading = true
      const payload = {
        subject: this.subject,
        message: this.message,
        recipients: {
          additionalEmails: [this.retailerEmail],
        },
      }
      store
        .dispatch(SEND_BROADCAST_MAIL, payload)
        .then(res => {
          this.isLoading = false
          apiToastSuccess(res.data.message)
          analytics.track(
            constants.TRACKS.ACTIONS.BRAND_SEND_MESSAGE,
            formatObject({
              subject: this.subject,
              message: removeHtmlTags(this.message),
              recipientList: this.retailerEmail,
              entryPoint: 'Wishlist',
            }),
          )
          this.$bvModal.hide('send-wishlist-mail-notification')
        })
        .catch(err => {
          this.isLoading = false
          apiToastWarning(err)
        })
    },

    resetForm() {
      this.subject = ''
      this.message = ''
      this.showForm = true
      this.isLoading = false
    },
    closeNotificationModal() {
      analytics.track(
        constants.TRACKS.ACTIONS.BRAND_CANCELS_SENDING_MESSAGE,
        formatObject({
          subject: this.subject,
          message: removeHtmlTags(this.message),
          recipientList: this.retailerEmail,
          entryPoint: 'Wishlist',
        }),
      )
      this.$bvModal.hide('send-wishlist-mail-notification')
    },
    showModal() {
      this.resetForm()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-notification.scss';
</style>
