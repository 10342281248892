<template>
  <div>
    <div
      v-if="isLoaded"
      :key="resetKey"
    >
      <b-tabs
        v-model="tabs"
        class="wishlist-tabs"
        nav-wrapper-class="wishlist-list"
      >
        <div
          v-if="canShowSearch"
          class="elements-center wishlist-search-bar pb-50"
        >
          <b-form-input
            v-model="search"
            placeholder="Search"
            class="search-wishlist pl-3"
            @input="searchWishlist"
          />
          <feather-icon
            icon="SearchIcon"
            size="18"
            class="wishlist-search-icon"
          />

          <div
            v-if="(isEntityAdmin || isSalesManager) && isBrand"
            class="ml-1 w-25"
          >
            <reps-list-dropdown
              :options="salesReps"
              :show-self-option="tabs === 0"
              @on-select-options="onFilterBySalesRep"
            />
          </div>

          <div
            v-if="isEntityAdmin && isBrand"
            class="ml-1 w-25"
          >
            <reps-list-dropdown
              :options="salesManagers"
              placeholder="Sales manager"
              :show-self-option="tabs === 0"
              @on-select-options="onFilterBySalesManager"
            />
          </div>

          <b-button
            v-if="isBrand && tabs === 0"
            variant="custom-primary"
            class="ml-1 text-nowrap"
            @click="onClickAddCollection"
          >
            <feather-icon
              icon="PlusIcon"
              size="18"
              class="align-text-bottom"
            />
            <span> Add Selection </span>
          </b-button>
        </div>
        <div
          v-if="tabs === 1"
          class="row"
        >
          <div class="col-3">
            <reps-list-dropdown
              placeholder="Collection"
              :options="wishlistCollections"
              @on-select-options="onFilterByCollection"
            />
          </div>
          <div class="col-3 pl-0">
            <reps-list-dropdown
              placeholder="Retailer"
              :options="wishlistRetailers"
              @on-select-options="onFilterByRetailer"
            />
          </div>
        </div>

        <b-tab :title="`My ${getWishlistTitle(isRetailer, true)}`">
          <div
            v-if="wishlists.length"
            class="mt-1"
          >
            <section class="wishlist-cards">
              <div
                v-for="wishlist in wishlists"
                :id="'wishlist_id_'+wishlist._id"
                :key="wishlist._id"
                class="wishlist-card-container"
              >
                <div :class="{'disable-pointer-events': isSelectedMultipleSelections}">
                  <wishlist-card
                    :wishlist="wishlist"
                    @fetchWishlists="fetchWishlists"
                    @open-referral-model="openReferralModel"
                  />
                </div>
                <b-form-checkbox
                  v-if="isBrand"
                  class="customCheck"
                  :class="{'d-block': isSelectedMultipleSelections}"
                  :checked-value="true"
                  :unchecked-value="false"
                  :checked="selectedSelections.includes(wishlist._id)"
                  @change="onSelectSelection(wishlist)"
                />
              </div>
            </section>
          </div>
          <template v-else>
            <div v-if="search || isSearching">
              <empty-products-message />
            </div>
            <div
              v-else
              class="pt-md-4 pt-2"
            >
              <span class="empty-icon-bg wishlist">
                <feather-icon
                  icon="SearchIcon"
                  size="22"
                />
              </span>
              <b-card-text class="mt-1">
                You don't have any {{ getWishlistTitle(isRetailer, true) }} yet.
                <br>
                Start adding new products from collections.
              </b-card-text>
              <div class="d-flex">
                <div id="explore_button">
                  <b-button
                    v-if="isRetailer"
                    :to="{ name: 'explore' }"
                    variant="custom-secondary"
                    :disabled="!isEnabledMarketplace"
                  >
                    Go to Collections
                  </b-button>
                </div>
                <b-button
                  v-if="isBrand"
                  variant="custom-secondary"
                  @click="onClickAddCollection"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="18"
                    class="align-text-bottom"
                  />
                  <span> Add Selection </span>

                </b-button>
              </div>
              <b-tooltip
                v-if="!isEnabledMarketplace"
                target="explore_button"
                placement="bottom"
              >
                {{ marketplaceAccessDeniedMessage }}
              </b-tooltip>
            </div>
          </template>
        </b-tab>
        <b-tab v-if="isRetailer">
          <template #title>
            <span>SHARED BY BRANDS</span>
          </template>
          <div
            v-if="isRetailer && wishlistsBrand.length"
            class="mt-1"
          >
            <section class="wishlist-cards">
              <wishlist-card
                v-for="wishlist in wishlistsBrand"
                :key="wishlist._id"
                :wishlist="wishlist"
                @fetchWishlists="fetchWishlists"
              />
            </section>
          </div>
          <template v-else>
            <div v-if="search || isSearching">
              <empty-products-message />
            </div>
            <div
              v-else
              class="pt-md-4 pt-2"
            >
              <span class="empty-icon-bg wishlist">
                <message-icon />
              </span>
              <b-card-text class="mt-1">
                No brand has shared the wishlist.
              </b-card-text>
            </div>
          </template>
        </b-tab>
        <b-tab
          v-if="isBrand"
          title="Wishlists with my products"
        >
          <div
            v-if="wishlistsRetailer.length"
            class="mt-1"
          >
            <section class="wishlist-cards">
              <wishlist-card-for-brand
                v-for="wishlist in wishlistsRetailer"
                :key="wishlist._id"
                :wishlist="wishlist"
                @fetchWishlists="fetchWishlists"
                @open-wishlist-notification="openWishlistNotification"
              />
            </section>
          </div>
          <div
            v-else
          >
            <div v-if="search || isSearching">
              <empty-products-message />
            </div>
            <div
              v-else
              class="pt-md-4 pt-2"
            >
              <span class="empty-icon-bg wishlist">
                <message-icon />
              </span>
              <b-card-text class="mt-1">No retailer has added your products to their
                wishlist.</b-card-text>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <b-card
      v-else
      class="elements-center p-3"
    >
      <b-spinner />
    </b-card>
    <referral-model
      :is-selection="true"
      :referral-email="referralEmail"
      :referral-wishlist-id="referralWishlistId"
      :wishlist="selectedWishlist"
    />
    <create-selection-modal
      v-if="isBrand"
      :fetch-wishlists="fetchWishlists"
    />
    <send-wishlist-mail-notification
      v-if="isBrand"
      :retailer-name="retailerName"
      :retailer-email="retailerEmail"
    />
    <tool-kit :toolkit-page="TOOLKIT_PAGE.WISHLIST" />
    <share-selections-error-modal
      :modal-id="shareSelectionsErrorModalId"
      :messages="shareSelectionResponseMessages"
    />
    <wishlist-action
      v-if="isSelectedMultipleSelections"
      :is-multiple-selections="isSelectedMultipleSelections"
      @fetchWishlists="fetchWishlists"
      @on-open-errors-popup="openShareSelectionsErrorModal"
    />
  </div>
</template>

<script>
import MessageIcon from '@/@core/assets/svg-components/MessageIcon.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'
import { apiToastErrorV2 } from '@/@core/utils/toast'
import { getWishlistTitle } from '@/@core/utils/utils'
import constants, { marketplaceAccessDeniedMessage, TOOLKIT_PAGE } from '@/constants'
import store from '@/store'
import { FETCH_DIRECT_RETAILERS } from '@/store/modules/connections.module'
import { FETCH_WISHLISTS } from '@/store/modules/shop.module'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'

import {
BButton,
BCard,
BCardText,
BFormCheckbox,
BFormInput,
BSpinner,
BTab,
BTabs,
BTooltip,
VBModal,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import { getSelfOption } from '@/service/user.service'
import { RESET_WISHLIST_NAV_INDICATOR } from '@/store/modules/notification.module'
import {
 RESET_SELECTED_SELECTIONS, SELECT_ALL_SELECTIONS, SELECT_OR_REMOVE_SELETION, shareSelectionsErrorModalId,
} from '@/store/modules/multi-select.module'
import UserRoleMixin from '../UserRoleMixin.vue'
import ReferralModel from '../referral/referralModel.vue'
import EmptyProductsMessage from '../shop/EmptyProductsMessage.vue'
import CreateSelectionModal from './CreateSelectionModal.vue'
import SendWishlistMailNotification from './SendWishlistMailNotification.vue'
import WishlistCard from './WishlistCard.vue'
import WishlistCardForBrand from './WishlistCardForBrand.vue'
import RepsListDropdown from '../wallet/RepsListDropdown.vue'
import WishlistAction from './WishlistAction.vue'
import ShareSelectionsErrorModal from './ShareSelectionsErrorModal.vue'

const { BRAND_ACCESS } = c

const { SHARE_MULTIPLE_SELECTIONS } = constants.TRACKS.ACTIONS

export default {
  components: {
    BCard,
    FeatherIcon,
    BFormInput,
    WishlistCard,
    BSpinner,
    BCardText,
    BButton,
    EmptyProductsMessage,
    ReferralModel,
    CreateSelectionModal,
    BTabs,
    BTab,
    BTooltip,
    MessageIcon,
    WishlistCardForBrand,
    SendWishlistMailNotification,
    RepsListDropdown,
    BFormCheckbox,
    WishlistAction,
    ShareSelectionsErrorModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      isLoaded: false,
      search: '',
      getWishlistTitle,
      fullWishlists: [],
      rawWishlists: [],
      rawWishlistsRetailer: [],
      wishlists: [],
      wishlistsBrand: [],
      wishlistsRetailer: [],
      debounce: null,
      isSearching: false,
      referralEmail: '',
      referralWishlistId: '',
      selectedWishlist: {},
      tabs: 0,
      retailerName: '',
      retailerEmail: '',
      marketplaceAccessDeniedMessage,
      TOOLKIT_PAGE,
      selectedRepsNames: [],
      selectedSalesManagerNames: [],
      selectedCollections: [],
      selectedRetailers: [],
      wishlistCollections: [],
      wishlistRetailers: [],
      resetKey: 0,
      shareSelectionsErrorModalId,
      shareSelectionResponseMessages: [],
    }
  },
  computed: {
    canShowSearch() {
      const isSearching = this.search || this.isSearching
      if (this.isRetailer && this.tabs === 1) {
        return this.fullWishlists.length || isSearching
      }
      if (this.isBrand && this.tabs === 1) {
        return this.fullWishlists.length || isSearching
      }
      return this.fullWishlists.length || isSearching
    },
    ...mapState({
      profile: state => state.auth.profileData,
      salesReps: state => state.auth.salesReps,
      salesManagers: state => state.auth.salesManagers,
    }),
    isEnabledMarketplace() {
      return this.profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
    },
    selectedSelections() {
        return this.$store.getters.getSelectedSelections || []
    },
    isSelectedMultipleSelections() {
      return !!this.selectedSelections.length
    },
  },
  watch: {
    tabs(val) {
      if (this.search) {
        this.search = ''
        this.fetchWishlists(this.search)
      } else {
        this.resetFilters()
      }
      if (val === 1) {
        this.resetSelectedSelections()
      }
    },
  },
  mounted() {
    this.$root.$refs.selection = this
    this.$store.commit(RESET_WISHLIST_NAV_INDICATOR)
    this.$store.dispatch(FETCH_DIRECT_RETAILERS)
    this.fetchWishlists()
  },
  destroyed() {
    this.resetSelectedSelections()
  },
  methods: {
    openShareSelectionsErrorModal(messages) {
      this.shareSelectionResponseMessages = []
      this.shareSelectionResponseMessages = messages
      this.$bvModal.show(this.shareSelectionsErrorModalId)
      analytics.track(SHARE_MULTIPLE_SELECTIONS.SELECTIONS_SHARING_RESULT_POPUP_OPENED, { shareSelectionsResponseMessages: messages })
    },
    selectAllSelections() {
      const selectedSelectionIds = this.wishlists.map(wishlist => wishlist._id)
      this.$store.commit(SELECT_ALL_SELECTIONS, selectedSelectionIds)
      analytics.track(SHARE_MULTIPLE_SELECTIONS.BRNAD_CLICKS_SELECT_ALL_SELECTIONS, { selectedSelectionIds })
    },
    resetSelectedSelections() {
      this.$store.commit(RESET_SELECTED_SELECTIONS)
    },
    onSelectSelection(wishlist) {
      this.$store.commit(SELECT_OR_REMOVE_SELETION, wishlist)
    },
    onFilterBySalesRep(selectedValues = []) {
      this.selectedRepsNames = selectedValues.map(s => s.name)
      this.updateFilter()
    },
    onFilterBySalesManager(selectedValues = []) {
      this.selectedSalesManagerNames = selectedValues.map(s => s.name)
      this.updateFilter()
    },
    onFilterByCollection(selectedValues = []) {
      this.selectedCollections = selectedValues.map(s => s.name)
      this.updateFilter()
    },
    onFilterByRetailer(selectedValues = []) {
      this.selectedRetailers = selectedValues.map(s => s.name)
      this.updateFilter()
    },
    updateFilter() {
      const selectedNames = [...this.selectedRepsNames, ...this.selectedSalesManagerNames]
      const selectedCollectionAndRetailerNames = [...this.selectedCollections, ...this.selectedRetailers]

      if (this.noFiltersSelected(selectedNames, selectedCollectionAndRetailerNames)) {
        this.resetFilters()
        return
      }

      const isSelectedSelf = selectedNames.includes(getSelfOption().name)
      this.applySalesRepFilter(selectedNames, isSelectedSelf)
      this.applyCollectionAndRetailerFilter(selectedNames, selectedCollectionAndRetailerNames)
    },
    noFiltersSelected(selectedNames, selectedCollectionAndRetailerNames) {
      return selectedNames.length === 0 && selectedCollectionAndRetailerNames.length === 0
    },
    resetFilters() {
      this.wishlists = this.rawWishlists
      this.wishlistsRetailer = this.rawWishlistsRetailer
      this.resetKey += 1
    },
    applySalesRepFilter(selectedNames, isSelectedSelf) {
      this.wishlists = this.rawWishlists.filter(wishlist => selectedNames.includes(wishlist.salesRep) || (isSelectedSelf && wishlist.isCreatedBySelf))
    },
    applyCollectionAndRetailerFilter(selectedNames, selectedCollectionAndRetailerNames) {
      this.wishlistsRetailer = this.rawWishlistsRetailer.filter(wishlist => selectedNames.includes(wishlist.salesRep) || selectedCollectionAndRetailerNames.includes(wishlist?.collectionName) || selectedCollectionAndRetailerNames.includes(wishlist?.retailerName))
    },
    fetchWishlists(search = '') {
      store
        .dispatch(FETCH_WISHLISTS, { force: true, search })
        .then(wishlists => {
          this.fullWishlists = wishlists
          if (this.isRetailer) {
            // Selection shared by brands
            this.wishlists = wishlists.filter(
              wishlist => !wishlist?.isBrandInitiated,
            )
            // Wishlists created by retailer
            this.wishlistsBrand = wishlists.filter(
              wishlist => wishlist?.isBrandInitiated,
            )
          } else {
            // Selection created by brand
            this.wishlists = wishlists.filter(
              wishlist => !wishlist?.isReadOnly,
            )
            // Wishlists created by retailer
            this.wishlistsRetailer = wishlists.filter(
              wishlist => wishlist?.isReadOnly,
            )
          }
          this.rawWishlists = this.wishlists
          this.rawWishlistsRetailer = this.wishlistsRetailer
          if (!this.isLoaded) {
            this.setFilterOptions()
          }
          this.isLoaded = true
          this.isSearching = false
        })
        .catch(err => {
          this.isLoaded = true
          this.isSearching = false
          apiToastErrorV2(err)
        })
    },
    setFilterOptions() {
      const uniqueRetailerIds = new Set()
      const uniqueCollectionIds = new Set()

      this.rawWishlistsRetailer.forEach((wishlist, index) => {
        if (wishlist.retailerName && !uniqueRetailerIds.has(wishlist.retailerName)) {
          this.wishlistRetailers.push({
            userId: `${wishlist.retailerName}${index}`,
            name: wishlist.retailerName,
          })
          uniqueRetailerIds.add(wishlist.retailerName)
        }
        if (wishlist.collectionName && !uniqueCollectionIds.has(wishlist.collectionName)) {
          this.wishlistCollections.push({
            userId: `${wishlist.collectionName}${index}`,
            name: wishlist.collectionName,
          })
          uniqueCollectionIds.add(wishlist.collectionName)
        }
      })
    },
    searchWishlist() {
      this.isSearching = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchWishlists(this.search)
      }, 500)
    },
    openReferralModel(email, wishlist) {
      this.selectedWishlist = wishlist
      this.referralEmail = email
      this.referralWishlistId = wishlist._id
      this.$bvModal.show('referal-model')
    },
    onClickAddCollection() {
      this.$bvModal.show('create_selection_modal')
      analytics.track(constants.TRACKS.ACTIONS.BRAND_CLICKS_ADD_SELECTION, {
        'Created By': new Date(),
      })
    },
    openWishlistNotification(name, email) {
      this.retailerEmail = email
      this.retailerName = name
      this.$bvModal.show('send-wishlist-mail-notification')
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-wishlist.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-shop.scss';

.wishlist-card-container
{
  position: relative;

.disable-pointer-events {
    pointer-events: none;

    .share-icon-position {
      rect {
        fill: $gray-100;
      }
    }
  }
  .customCheck {
    position: absolute;
    top: 0;
    display: none;
    margin: 10px;
    z-index: 0 !important;
    user-select: none
  }
  &:hover {
    .wishlist-card-details {
      border: 1px solid rgba($black, 0.363);
      border-radius: 6px;
    }
    .customCheck {
      display: initial;
    }
  }
}
</style>
